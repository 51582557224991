<template>
  <a-upload
    v-bind="$attrs"
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    @change="handleChange"
    :fileList="fileList"
    :remove="handleRemove"
    :style="cssVars"
  >
    <!-- remove -->
    <a-icon
      v-if="!$attrs.disabled && imageUrl && imageUrl.length"
      :type="'close-circle'"
      class="avatar-uploader__remove"
      @click.stop="handleRemove"
    />
    <img
      v-if="imageUrl"
      :src="imageUrl"
      @error="handleRemove"
      alt="avatar"
      class="avatar"
      :width="$attrs.width || '100px'"
      :height="$attrs.height || 'auto'"
      :style="{ ...cssVars, borderRadius: $attrs.borderRadius || '50%' }"
    />
    <div v-else :style="cssVars" class="avatar-uploader__text">
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">Cargar</div>
    </div>
  </a-upload>
</template>
<script>
import { getBase64 } from "@/utils/utils";
import _ from "lodash";
export default {
  props: {
    // must be included in props
    value: {
      type: null,
    },
  },
  data() {
    return {
      loading: false,
      imageUrl: "",
      fileList: [],
    };
  },
  methods: {
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }

      if (info.fileList && info.fileList.length > 0) {
        const file = info.fileList.pop();
        getBase64(file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.fileList = [file];
          this.loading = false;
        });
      } else {
        this.imageUrl = "";
        this.loading = false;
      }
    },
    beforeUpload() {
      // const isJpgOrPng =
      //   file.type === "image/jpeg" || file.type === "image/png";
      // if (isJpgOrPng) {
      //   return false;
      // }

      // return new Promise((_, reject) => {
      //   this.$message.error("Solo se permiten imagenes JPG & PNG!", 5);
      //   reject(file);
      // });
      return false;
    },
    handleRemove() {
      this.imageUrl = "";
      this.fileList = [];
    },
  },
  computed: {
    cssVars() {
      let {
        width = "128px",
        height = "128px",
        borderRadius = "50%",
      } = this.$attrs;
      return {
        "--width": _.isNumber(width) ? `${this.$attrs.width}px` : width,
        "--height": _.isNumber(height) ? `${this.$attrs.height}px` : height,
        "--border-radius": _.isNumber(borderRadius)
          ? `${this.$attrs.borderRadius}px`
          : borderRadius,
      };
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
      this.imageUrl = this.value;
    }
  },
  watch: {
    imageUrl(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
      this.imageUrl = this.value;
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.avatar-uploader > .ant-upload {
  position: relative;
  border-radius: var(--border-radius);
  width: var(--width);
  height: var(--height);
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.avatar-uploader {
  &__remove {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    color: color(danger) !important;
  }
  &__text {
    width: var(--width);
    height: var(--height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
