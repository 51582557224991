<template>
  <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules">
    <a-form-item
      slot-scope="{ errors, flags }"
      :label="$attrs.label"
      :align="$attrs.align || 'left'"
      :validateStatus="resolveState({ errors, flags })"
      :help="errors[0]"
    >
      <a-select
        v-bind="$attrs"
        :style="{ ...$attrs.customStyle }"
        :value="innerValue"
        v-model="innerValue"
        showSearch
        @change="handleChange"
        @popupScroll="onPopupScroll"
        @focus="onFocus"
        @search="debounceSearch"
        @dropdownVisibleChange="onDropdownVisibleChange"
        :filterOption="filterOption"
        :loading="isLoading"
        :disabled="disabled"
      >
        <component
          slot="not-found-content"
          :is="isLoading ? 'a-spin' : 'a-empty'"
          size="small"
          key="not-found-content"
          :image="Empty.PRESENTED_IMAGE_SIMPLE"
        />
        <a-select-option
          v-for="item in dataSource"
          :key="item[valueKey]"
          :objectvalue="item"
          :value="item[valueKey]"
          :label="item[labelKey]"
          v-bind="$attrs.titleKey ? { title: item[$attrs.titleKey] } : {}"
        >
          <slot name="renderOption" :data="item">
            {{ item[labelKey] }}
          </slot>
        </a-select-option>
      </a-select>
    </a-form-item>
  </ValidationProvider>
</template>
<script>
import _ from "lodash";
import { Exception } from "@/utils/Exception";
import labels from "@/utils/labels";
import { Empty } from "ant-design-vue";
import { ValidationProvider } from "vee-validate";
let initialState = {
  hasError: false,
  isLoading: false,
  innerValue: undefined,
  dataSource: [],
  options: {
    page: 1,
    search: "",
    limit: 20,
    hasMore: true,
    sortBy: "ASC",
    orderBy: "ASC",
    filters: {},
  },
};
export default {
  components: {
    ValidationProvider,
  },
  created() {
    if (!this.disabledDefaultFetchOptions) {
      this.handleFetch(this.options);
    }
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String, Array],
      default: "",
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    disabledDefaultFetchOptions: {
      type: Boolean,
      default: true,
    },
    defaultActiveFirstOption: {
      type: Boolean,
      default: false,
    },
    filterOption: {
      type: [Function, Boolean],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object, Array],
      default: undefined,
    },
    sortOptionsBy: {
      type: String,
      default: undefined,
    },
    disabledSortBy: {
      type: Boolean,
      default: false,
    },
    labelKey: {
      type: String,
      required: true,
      default: "name",
    },
    valueKey: {
      type: String,
      required: true,
      default: "id",
    },
    autoComplete: {
      type: String,
      default: "off",
    },
  },
  data() {
    return {
      ..._.cloneDeep(initialState),
      dataSource: _.cloneDeep(this.data) || [],
      options: {
        ..._.cloneDeep(initialState.options),
        ...(!this.disabledSortBy && {
          sortBy: {
            [this.sortOptionsBy ? this.sortOptionsBy : this.labelKey]: this
              .$attrs.orderBy
              ? this.$attrs.orderBy
              : "ASC",
          },
        }),
        orderBy: this.$attrs.orderBy ? this.$attrs.orderBy : "ASC",
        filters: this.$attrs.filters ? this.$attrs.filters : {},
      },
      innerValue:
        !!this.value && !_.isString(this.value)
          ? _.isArray(this.value)
            ? (this.value || []).map((val) => val[this.valueKey])
            : this.value[this.labelKey]
          : undefined,
    };
  },
  computed: {
    Empty() {
      return Empty;
    },
  },
  methods: {
    handleFetch(request) {
      const { fetchService } = this.$attrs;
      if (fetchService) {
        this.fetchService(request);
      }
    },
    fetchService(options) {
      const { fetchService } = this.$attrs;
      const { labelKey, valueKey } = this;
      if (fetchService) {
        this.isLoading = true;
        fetchService(options).then((response) => {
          if (response) {
            this.handleResponse(response);
          }
        });
      } else {
        Object.assign(this, {
          isLoading: false,
          hasError: true,
          value: {
            [labelKey]: "Error inesperado!",
            [valueKey]: "error",
          },
        });
      }
    },
    handleResponse(response) {
      const { labelKey, valueKey } = this;
      try {
        if ("data" in response === false) {
          throw new Exception(null, labels.exception.ERROR_NOT_FOUND, 404);
        }
        const sort = Object.keys(this.options.sortBy)[0];
        const orderBy = this.options.sortBy[sort];
        const data = response.data;
        Object.assign(this, {
          isLoading: false,
          dataSource: _.orderBy(
            _.unionWith(this.dataSource, data.data, _.isEqual),
            [(item) => item[sort]?.toString()?.toLowerCase()],
            [orderBy]
          ),
          options: {
            ...this.options,
            hasMore: data.pagination.more,
          },
        });
      } catch (err) {
        this.isLoading = false;
        if ("stack" in err) {
          Object.assign(this, {
            hasError: true,
            innerValue: {
              [labelKey]: labels.exception.UNEXPECTED_ERROR,
              [valueKey]: "error",
            },
          });
        }
      }
    },
    handleChange(value, option) {
      const { onChange } = this.$attrs;
      try {
        Object.assign(this, {
          innerValue: value,
          options: {
            ...this.options,
            page: this.options.page,
            hasMore: true,
            search: "",
          },
        });
        let newValue = option
          ? _.isArray(option)
            ? option.map((opt) => opt.data.attrs.objectvalue)
            : option.data.attrs.objectvalue
          : null;
        if (onChange !== undefined && onChange instanceof Function) {
          onChange(newValue);
        }
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      } catch (error) {
        this.$log.error(error.message);
      }
    },
    debounceSearch(search) {
      _.debounce(
        !this.data?.length ? this.onSearch : this.onLocalSearch,
        300
      )(search);
    },
    onLocalSearch(search) {
      if (search.length > 0) {
        const { labelKey } = this;
        const dataSource = _.cloneDeep(this.data).filter((item) =>
          item[labelKey]
            ?.toString()
            ?.toLowerCase()
            ?.includes(search.toString().toLowerCase())
        );
        Object.assign(this, {
          dataSource: dataSource,
          options: {
            ...this.options,
            search: search,
          },
        });
      } else {
        Object.assign(this, {
          dataSource: this.data,
          options: {
            ...this.options,
            search: search,
          },
        });
      }
    },
    onSearch(search) {
      if (search.length > 0) {
        Object.assign(this, {
          dataSource: [],
          options: {
            ...this.options,
            search: search,
            page: 1,
          },
        });
        if (this.options.search.length > 0) {
          this.handleFetch(this.options);
        }
      } else {
        this.handleFetch(_.cloneDeep(initialState).options);
      }
    },
    onPopupScroll(event) {
      let target = event.target;
      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        const {
          options: { hasMore },
        } = this;

        if (hasMore) {
          Object.assign(this, {
            options: {
              ...this.options,
              page: this.options.page + 1,
            },
          });
          this.handleFetch(this.options);
        }
      }
    },
    onFocus() {
      // Launched on first open
      this.$emit("focus");
      if (this.dataSource?.length < 1) {
        // reset state
        this.resetState();
        this.handleFetch(this.options);
      }

      if (this.autoComplete === "off") {
        let i;
        const el = document.getElementsByClassName("ant-select-search__field");
        for (i = 0; i < el.length; i++) {
          el[i].setAttribute("autocomplete", "registration-select");
        }
      }
    },
    /* Reset state to default values */
    resetState() {
      Object.assign(this, {
        ..._.cloneDeep(initialState),
        innerValue: this.innerValue || initialState.innerValue,
      });
    },
    /* Change first level values in state */
    setStateValue(stateName, value, fetchAfterSetState = false) {
      this.$set(this, stateName, value);
      if (fetchAfterSetState) {
        this.handleFetch(this.options);
      }
    },
    /* Change second level options values in state */
    setStateOptions(filterName, value, fetchAfterSetState = false) {
      this.$set(this.options, filterName, value);
      if (fetchAfterSetState) {
        this.handleFetch(this.options);
      }
    },
    /* Change Third level filters values in state */
    setStateFiltersOption(filterName, value, fetchAfterSetState = false) {
      this.resetState();
      this.$set(this, "value", value);
      this.$set(this.options.filters, filterName, value);
      if (fetchAfterSetState) {
        this.handleFetch(this.options);
      }
    },
    resolveState({ errors, flags }) {
      if (errors[0]) {
        return "error";
      }

      if (flags.pending) {
        return "validating";
      }

      if (flags.valid) {
        return "success";
      }

      return "";
    },
    onDropdownVisibleChange(open) {
      if (!open && this.options.search.length > 0) {
        this.resetState();
        this.handleFetch(this.options);
      }
    },
    selectAll() {
      const { onChange, mode } = this.$attrs;
      const { dataSource = [] } = this;
      if (mode === "multiple") {
        if (onChange !== undefined && onChange instanceof Function) {
          onChange(dataSource);
        }
        this.$emit("input", dataSource);
        this.$emit("change", dataSource);
      }
    },
  },
  watch: {
    innerValue(value) {
      const { mode, onChange } = this.$attrs;
      const { dataSource = [], defaultActiveFirstOption } = this;
      if (!mode && !value && dataSource.length && defaultActiveFirstOption) {
        if (onChange !== undefined && onChange instanceof Function) {
          onChange(dataSource[0]);
        }
        this.$emit("input", dataSource[0]);
        this.$emit("change", dataSource[0]);
      }
    },
    value(newValue) {
      this.innerValue =
        !!newValue && !_.isString(newValue)
          ? _.isArray(newValue)
            ? (newValue || []).map((val) => val[this.valueKey])
            : newValue[this.labelKey]
          : undefined;
    },
    data: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.dataSource = _.cloneDeep(newVal) || [];
        }
      },
    },
  },
};
</script>
