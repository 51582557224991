export const Exception = class extends Error {
  constructor(
    data = null,
    message = "",
    code = 500,
    errors = [],
    redirect = null,
    ...params
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, Error);
    }

    this.name = "Excepción";
    this.data = data;
    this.message = message;
    this.code = code;
    this.additional_errors = errors;
    this.redirect = redirect;
    this.has_error = this.isError(this.code);
  }

  isError(code) {
    if (code >= 200 && code <= 299) {
      return false;
    }
    if (code >= 300 && code <= 399) {
      return false;
    }
    if (code >= 400 && code <= 499) {
      return true;
    }
    if (code >= 500 && code <= 599) {
      return true;
    }
    return true;
  }
};
