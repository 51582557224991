<template>
  <div class="lotteries-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <a-icon
                  :type="isEdit ? 'edit' : 'lottery-add'"
                  class="form-icon"
                />
                <span>{{
                  isEdit ? labels.form.titleEdit : labels.form.titleAdd
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col v-bind="isMobile && { span: 24 }">
                  <a-button-group>
                    <a-button
                      type="danger"
                      icon="arrow-left"
                      ghost
                      @click="handleCancel"
                      v-bind="isMobile && { block: true, class: 'w-100' }"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <template v-if="canEdit">
                      <a-button
                        type="primary"
                        ghost
                        :loading="submitting"
                        @click="validate().then(() => handleSubmit(true))"
                        v-bind="isMobile && { block: true, class: 'w-100' }"
                      >
                        <span>{{ labels.form.saveAndClose }}</span>
                      </a-button>
                      <a-button
                        type="primary"
                        :loading="submitting"
                        @click="validate().then(handleSubmit)"
                        v-bind="isMobile && { block: true, class: 'w-100' }"
                      >
                        <span>{{ labels.form.save }}</span>
                      </a-button>
                    </template>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="start" :gutter="[30, 30]">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :span="24">
                    <h2>
                      <a-icon :type="isEdit ? 'edit' : 'folder'" />
                      {{ labels.form.baseInfo }}
                    </h2>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :span="24">
                    <h3>{{ labels.form.logo }}</h3>
                  </a-col>
                  <a-col :span="24">
                    <UploadAvatar
                      v-model="form.logo"
                      :accept="allowedImageTypes"
                      :width="200"
                      :height="null"
                      borderRadius="0"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row
                  type="flex"
                  justify="start"
                  :gutter="24"
                  :style="{ alignItems: 'end' }"
                >
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      v-model.trim="form.name"
                      :label="labels.form.name.label"
                      rules="required"
                      :placeholder="labels.form.name.placeholder"
                      :allowClear="true"
                      :showLabel="false"
                      :addonBefore="labels.form.name.label"
                      :readOnly="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      v-model.trim="form.abbreviated"
                      :label="labels.form.abbreviated.label"
                      rules="required|max:6"
                      :maxLength="6"
                      :placeholder="labels.form.abbreviated.placeholder"
                      @input="upperCase($event, 'abbreviated')"
                      :allowClear="true"
                      :showLabel="false"
                      :addonBefore="labels.form.abbreviated.label"
                      :readOnly="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <SelectPagination
                      v-model="form.country"
                      :data="countriesOptions"
                      :placeholder="labels.form.country.placeholder"
                      :labelKey="'label'"
                      :valueKey="'value'"
                      :label="labels.form.country.label"
                      rules="required"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <SwitchInput
                      v-model.lazy="form.status"
                      :label="labels.form.status"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <h2>
                      <a-icon type="calendar" />
                      {{ labels.form.startTime }}
                    </h2>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <Checkbox
                      :label="labels.form.copyMondayTimeToOtherDate"
                      v-model="startTimeCopied"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="8">
                    <TimePicker
                      v-model="form.monday_start_time"
                      :label="labels.form.mondayTime.label"
                      :placeholder="labels.form.mondayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="8">
                    <TimePicker
                      v-model="form.tuesday_start_time"
                      :label="labels.form.tuesdayTime.label"
                      :placeholder="labels.form.tuesdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="8">
                    <TimePicker
                      v-model="form.wednesday_start_time"
                      :label="labels.form.wednesdayTime.label"
                      :placeholder="labels.form.wednesdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.thursday_start_time"
                      :label="labels.form.thursdayTime.label"
                      :placeholder="labels.form.thursdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.friday_start_time"
                      :label="labels.form.fridayTime.label"
                      :placeholder="labels.form.fridayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.saturday_start_time"
                      :label="labels.form.saturdayTime.label"
                      :placeholder="labels.form.saturdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.sunday_start_time"
                      :label="labels.form.sundayTime.label"
                      :placeholder="labels.form.sundayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>

                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <h2>
                      <a-icon type="calendar" />
                      {{ labels.form.endTime }}
                    </h2>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <Checkbox
                      :label="labels.form.copyMondayTimeToOtherDate"
                      v-model="endTimeCopied"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="8">
                    <TimePicker
                      v-model="form.monday_end_time"
                      :label="labels.form.mondayTime.label"
                      :placeholder="labels.form.mondayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="8">
                    <TimePicker
                      v-model="form.tuesday_end_time"
                      :label="labels.form.tuesdayTime.label"
                      :placeholder="labels.form.tuesdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="8">
                    <TimePicker
                      v-model="form.wednesday_end_time"
                      :label="labels.form.wednesdayTime.label"
                      :placeholder="labels.form.wednesdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.thursday_end_time"
                      :label="labels.form.thursdayTime.label"
                      :placeholder="labels.form.thursdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.friday_end_time"
                      :label="labels.form.fridayTime.label"
                      :placeholder="labels.form.fridayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.saturday_end_time"
                      :label="labels.form.saturdayTime.label"
                      :placeholder="labels.form.saturdayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <TimePicker
                      v-model="form.sunday_end_time"
                      :label="labels.form.sundayTime.label"
                      :placeholder="labels.form.sundayTime.placeholder"
                      :rules="timeRules"
                      use12Hours
                      :format="timeFormat"
                      :allowClear="true"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>

                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :span="24">
                    <h2>
                      <font-awesome-icon
                        :icon="['far', 'money-bill-1']"
                        :class="'anticon'"
                      />
                      {{ labels.form.rewards }}
                    </h2>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="6">
                    <h2>{{ labels.form.quiniela }}</h2>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.quiniela_first"
                      :label="labels.form.first.label"
                      :placeholder="labels.form.first.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.first.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.quiniela_second"
                      :label="labels.form.second.label"
                      :placeholder="labels.form.second.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.second.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.quiniela_third"
                      :label="labels.form.third.label"
                      :placeholder="labels.form.third.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.third.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="6">
                    <h2>{{ labels.form.pale }}</h2>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.pale_first"
                      :label="labels.form.first.label"
                      :placeholder="labels.form.first.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.first.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.pale_second"
                      :label="labels.form.second.label"
                      :placeholder="labels.form.second.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.second.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="6">
                    <h2>{{ labels.form.tripleta }}</h2>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.tripleta_first"
                      :label="labels.form.first.label"
                      :placeholder="labels.form.first.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.first.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.tripleta_second"
                      :label="labels.form.second.label"
                      :placeholder="labels.form.second.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.second.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="6">
                    <h2>{{ labels.form.superpale }}</h2>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="6">
                    <Input
                      type="number"
                      v-model="form.superpale_first"
                      :label="labels.form.first.label"
                      :placeholder="labels.form.first.placeholder"
                      :rules="'required|decimal:2|min:0'"
                      :allowClear="true"
                      :addon-before="labels.form.first.label"
                      :showLabel="false"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>

                <!-- DESCRIPTION TEXT EDITOR -->
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :span="24">
                    <h2>
                      <font-awesome-icon
                        :icon="['far', 'file-code']"
                        :class="'anticon'"
                      />
                      {{ labels.form.description.label }}
                      <a-tooltip
                        :title="labels.form.description.tooltip"
                        trigger="click"
                      >
                        <a-icon type="info-circle" @click="() => {}" />
                      </a-tooltip>
                    </h2>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :span="24">
                    <a-form-item>
                      <wysiwyg v-if="canEdit" v-model="form.description" />
                      <div v-html="form.description" v-else />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "../../../components/core/VeeValidateForm/Input.vue";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import TimePicker from "../../../components/core/VeeValidateForm/TimePicker.vue";
import SwitchInput from "../../../components/core/VeeValidateForm/Switch.vue";
import Checkbox from "../../../components/core/VeeValidateForm/Checkbox.vue";
import UploadAvatar from "../../../components/core/VeeValidateForm/Upload.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import locale from "ant-design-vue/es/date-picker/locale/es_ES";
import router from "@/router";
import { adminPageTitle, hasRoles, roles } from "../../../utils/utils";

const initialFormState = {
  logo: "",
  name: "",
  abbreviated: "",
  monday_start_time: null,
  monday_end_time: null,
  tuesday_start_time: null,
  tuesday_end_time: null,
  wednesday_start_time: null,
  wednesday_end_time: null,
  thursday_start_time: null,
  thursday_end_time: null,
  friday_start_time: null,
  friday_end_time: null,
  saturday_start_time: null,
  saturday_end_time: null,
  sunday_start_time: null,
  sunday_end_time: null,
  quiniela_first: undefined,
  quiniela_second: undefined,
  quiniela_third: undefined,
  pale_first: undefined,
  pale_second: undefined,
  tripleta_first: undefined,
  tripleta_second: undefined,
  superpale_first: undefined,
  status: false,
  description: "",
  country: [],
};

export default {
  name: "LotteryForm",
  components: {
    ValidationObserver,
    Input,
    SwitchInput,
    TimePicker,
    UploadAvatar,
    Checkbox,
    SelectPagination,
  },
  metaInfo: {
    title: adminPageTitle(labels.lotteries.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("edit")
          ? labels.lotteries.pageTitleEditing
          : labels.lotteries.pageTitleCreate
      }`;
    },
  },
  data() {
    return {
      locale,
      startTimeCopied: false,
      endTimeCopied: false,
      labels: labels.lotteries,
      exception: labels.exception,
      loading: false,
      submitting: false,
      form: _.cloneDeep(initialFormState),
    };
  },
  async created() {
    this.resetForm();
    this.fetchAdminCountries();
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  computed: {
    ...mapState("lotteries", ["lottery"]),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    ...mapState("systemConfig", ["countries"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    isEdit() {
      return !!this.$route.params?.id;
    },
    canEdit() {
      const { accessRoles } = this.getLoggedUser;
      return (
        !this.isEdit ||
        hasRoles(accessRoles, [roles.admin.ROLE_LOTTERIES_UPDATE])
      );
    },
    timeFormat() {
      return "hh:mm A";
    },
    timeApiFormat() {
      return "HH:mm";
    },
    timeRules() {
      return {
        required: true,
        validTime: true,
      };
    },
    allowedImageTypes() {
      return `image/*`;
    },
    countriesOptions() {
      return this.countries.map((country) => ({
        label: country,
        value: country,
      }));
    },
  },
  methods: {
    ...mapActions("systemConfig", ["fetchAdminCountries"]),
    ...mapActions("lotteries", [
      "fetchLottery",
      "createLottery",
      "updateLottery",
      "deleteLottery",
      "resetForm",
    ]),
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchLottery(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$router.push({ name: "admin.lotteries" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let editting = this.isEdit;
      let form = _.cloneDeep(this.form);
      try {
        Object.keys(form).forEach((key) => {
          if (
            form[key] &&
            typeof form[key] === "object" &&
            "format" in form[key]
          ) {
            form[key] = form[key].format(this.timeApiFormat);
          } else if (form[key] === "") {
            form[key] = null;
          } else if (key === "country") {
            form[key] = form[key].value;
          }
        });
        if (editting) {
          response = await this.updateLottery(form);
        } else {
          response = await this.createLottery(form);
        }
        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.lotteries" });
        } else {
          if (!editting) {
            this.resetForm();
            this.$router.push({
              name: "admin.lotteries.edit",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "admin.lotteries" });
    },
    async onlyAlfa(value = "", field) {
      this.form[field] = await value.replace(/[^a-zA-Z\s]/g, "");
    },
    async upperCase(value = "", field) {
      this.form[field] = await value.toUpperCase().replace(/[^A-Z]+/g, "");
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    lottery: {
      handler(lottery = {}) {
        Object.assign(
          this.form,
          lottery && !_.isEmpty(lottery)
            ? _.cloneDeep({
                ...lottery,
                ...(lottery.country && {
                  country: {
                    value: lottery.country,
                    label: lottery.country,
                  },
                }),
                status: !!lottery.status,
                monday_start_time: this.$moment(
                  lottery.monday_start_time,
                  "HH:mm:ss"
                ),
                monday_end_time: this.$moment(
                  lottery.monday_end_time,
                  "HH:mm:ss"
                ),
                tuesday_start_time: this.$moment(
                  lottery.tuesday_start_time,
                  "HH:mm:ss"
                ),
                tuesday_end_time: this.$moment(
                  lottery.tuesday_end_time,
                  "HH:mm:ss"
                ),
                wednesday_start_time: this.$moment(
                  lottery.wednesday_start_time,
                  "HH:mm:ss"
                ),
                wednesday_end_time: this.$moment(
                  lottery.wednesday_end_time,
                  "HH:mm:ss"
                ),
                thursday_start_time: this.$moment(
                  lottery.thursday_start_time,
                  "HH:mm:ss"
                ),
                thursday_end_time: this.$moment(
                  lottery.thursday_end_time,
                  "HH:mm:ss"
                ),
                friday_start_time: this.$moment(
                  lottery.friday_start_time,
                  "HH:mm:ss"
                ),
                friday_end_time: this.$moment(
                  lottery.friday_end_time,
                  "HH:mm:ss"
                ),
                saturday_start_time: this.$moment(
                  lottery.saturday_start_time,
                  "HH:mm:ss"
                ),
                saturday_end_time: this.$moment(
                  lottery.saturday_end_time,
                  "HH:mm:ss"
                ),
                sunday_start_time: this.$moment(
                  lottery.sunday_start_time,
                  "HH:mm:ss"
                ),
                sunday_end_time: this.$moment(
                  lottery.sunday_end_time,
                  "HH:mm:ss"
                ),
              })
            : _.cloneDeep(initialFormState)
        );
      },
      deep: true,
    },
    startTimeCopied: {
      handler(copied) {
        let isValidTime = this.$moment(
          this.form.monday_start_time,
          "hh:mm A",
          true
        ).isValid();
        if (copied && this.form.monday_start_time && isValidTime) {
          let time = this.$moment(this.form.monday_start_time, "hh:mm A");
          this.form.tuesday_start_time = time;
          this.form.wednesday_start_time = time;
          this.form.thursday_start_time = time;
          this.form.friday_start_time = time;
          this.form.saturday_start_time = time;
          this.form.sunday_start_time = time;
        } else {
          this.form.tuesday_start_time = null;
          this.form.wednesday_start_time = null;
          this.form.thursday_start_time = null;
          this.form.friday_start_time = null;
          this.form.saturday_start_time = null;
          this.form.sunday_start_time = null;
        }
      },
      deep: true,
    },
    endTimeCopied: {
      handler(copied) {
        let isValidTime = this.$moment(
          this.form.monday_end_time,
          "hh:mm A",
          true
        ).isValid();
        if (copied && this.form.monday_end_time && isValidTime) {
          let time = this.$moment(this.form.monday_end_time, "hh:mm A");
          this.form.tuesday_end_time = time;
          this.form.wednesday_end_time = time;
          this.form.thursday_end_time = time;
          this.form.friday_end_time = time;
          this.form.saturday_end_time = time;
          this.form.sunday_end_time = time;
        } else {
          this.form.tuesday_end_time = null;
          this.form.wednesday_end_time = null;
          this.form.thursday_end_time = null;
          this.form.friday_end_time = null;
          this.form.saturday_end_time = null;
          this.form.sunday_end_time = null;
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.lotteries-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
  .lottery {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    padding: 15px 0;
    &-name {
      font-size: 22px;
      font-weight: bold;
    }
    &-joined {
      font-size: 14px;
      color: color(--gray-1);
      span {
        font-weight: bold;
      }
    }
  }
  .ant-checkbox-wrapper {
    span {
      font-size: 20px !important;
      font-weight: 700;
    }
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-btn-group {
  width: 100%;
  .w-100 {
    width: 100%;
  }
}
</style>
