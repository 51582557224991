<template>
  <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules">
    <a-form-item
      slot-scope="{ errors, flags }"
      :align="$attrs.align || 'left'"
      :validateStatus="resolveState({ errors, flags })"
      :help="errors[0]"
      :htmlFor="$attrs.htmlFor"
      :labelCol="$attrs.labelCol"
    >
      <a-checkbox
        v-bind="$attrs"
        :style="{ ...($attrs.customStyle || {}) }"
        v-model="innerValue"
        @change="onChange"
        >{{ $attrs.label }}</a-checkbox
      >
    </a-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    innerValue: false,
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = !!this.value;
    }
  },
  methods: {
    resolveState({ errors, flags }) {
      if (errors[0]) {
        return "error";
      }

      if (flags.pending) {
        return "validating";
      }

      if (flags.valid) {
        return "success";
      }

      return "";
    },
  },
};
</script>
